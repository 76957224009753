import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.03a7a9.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.31593f.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.e275ed.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.dc8048.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.adeea1.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.9cd7c0.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.dd252f.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.f4b776.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.0d1d0e.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.fd13d7.svg')